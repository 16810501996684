import * as React from "react"
import { graphql, PageProps } from "gatsby"

// Components
import Layout from "components/Layout"
import Main from "components/Layout/Main"
import UserMenu from "components/Sections/User/UserMenu"
import UserSidebar from "components/Sections/User/UserSidebar"
import SongItem from "components/Sections/Songs/SongItem"
import Pagination from "components/UIElements/Pagination"
// Models
import { IUser } from "models/User"
import { ISong } from "models/Song"
import { IOptionalMetaProps } from "models/Seo"

type DataProps = {
  user: IUser
  translations: {
    nodes: ISong[]
    count: number
  }
  articles: {
    count: number
  }
}

type PageContextProps = {
  currentPage: number
  totalPages: number
}

const UserPage: React.FC<PageProps<DataProps, PageContextProps>> = ({ data, pageContext }) => {
  const { currentPage, totalPages } = pageContext
  const { user, translations, articles } = data

  const seo: IOptionalMetaProps = {
    title: `Traducciones de ${user.name}`,
    author: { name: user.name, twitter: user.twitter },
    description: `Traducciones publicadas por ${user.name}${
      user.description && `. Información sobre ${user.name}: ${user.description}`
    }.`,
    image: user.avatar.url,
  }

  return (
    <Layout seo={seo}>
      <Main sidebar>
        <h2 className="title">Traducciones de {user.name}</h2>
        <UserMenu slug={user.slug} hasTranslations={!!translations} />
        <div>
          {translations.nodes.map(song => (
            <SongItem key={song.fields.slug} song={song} />
          ))}
        </div>
        <Pagination
          url={`/users/${user.slug}/translations`}
          title="Traducciones"
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </Main>
      <UserSidebar translationsCount={translations.count} articlesCount={articles.count} user={user} />
    </Layout>
  )
}

export default UserPage

export const pageQuery = graphql`
  query UserTranslationsPage($slug: String!, $limit: Int!, $skip: Int!) {
    user: user(slug: { eq: $slug }) {
      name
      slug
      description
      twitter
      facebook
      instagram
      youtube
      spotify
      telegram
      avatar {
        url
        childImageSharp {
          gatsbyImageData(width: 100, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }

    translations: allMdx(
      filter: { frontmatter: { translator: { eq: $slug } }, fields: { collection: { eq: "songs" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      count: totalCount
      nodes {
        fields {
          slug
          author_name
        }
        frontmatter {
          title
          date(formatString: "DD [de] MMMM, YYYY", locale: "es")
          authors
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }

    articles: allMdx(filter: { frontmatter: { writer: { eq: $slug } }, fields: { collection: { eq: "articles" } } }) {
      count: totalCount
    }
  }
`
